import { useFeatureToggleV2 } from '@/hooks'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { CreateRedeemableItemStoreEvents } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'
import { Button } from '@hexa-ui/components'
import { Plus } from '@hexa-ui/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const CreateRedeemableItemButton = React.forwardRef(() => {
	const icon = useCallback(() => <Plus size="large" />, [])
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()

	const onClickHandler = useCallback(
		(evt: React.MouseEvent) => {
			evt.preventDefault()
			CreateRedeemableItemStoreEvents.onOpen()
			sendAnalyticsServiceEventButtonClickedEvent('Redeemables List', 'Create redeemable - Empty', 'Create redeemable')
		},
		[sendAnalyticsServiceEventButtonClickedEvent],
	)

	const { t } = useTranslation()

	const isNewTransactionLogsToggleEnabled = useFeatureToggleV2(FeatureToggle.NEW_TRANSACTION_LOGS)

	const buttonI18nText = isNewTransactionLogsToggleEnabled
		? 'redeemable:BUTTON.ADD_NEW'
		: 'redeemable:BUTTON.CREATE_NEW'

	return (
		<Button
			icon={icon}
			leading
			size="medium"
			variant="primary"
			data-testid="redeemable-items-button-create"
			onClick={onClickHandler}
		>
			{t(buttonI18nText)}
		</Button>
	)
})
