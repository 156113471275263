import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph } from '@hexa-ui/components'
import * as currencyHelper from '@/utils/currencyHelper'
import { useFormContext } from 'react-hook-form'
import {
	Card,
	ContentCardViewMode,
	ItemStyleExcusionViewMode,
	ItemStyleViewMode,
	SectionStyleViewMode,
} from './SettingsCard.styles'
import StatusIndicator from './SettingsCardStatus/settingsCardStatus'

import { CreateEarningRuleFormData } from '../../Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { CreateExclusionRuleFormData } from '../../Pages/CreateExclusionPageRule/context/CreateExclusionListProvider/CreateExclusionListProvider.types'

interface SettingsCardProps {
	readonly isEarning: boolean
}

export function SettingsCardView({ isEarning }: Readonly<SettingsCardProps>) {
	const { t } = useTranslation()
	const { getValues } = useFormContext<CreateEarningRuleFormData>()
	const { getValues: getExclusionValues } = useFormContext<CreateExclusionRuleFormData>()

	const ruleDescriptionExclusion = getExclusionValues('description')

	const rule = t('rules:EARNING_NAME', {
		point: getValues('points'),
		amountSpent: currencyHelper.format(getValues('amountSpent')),
	})
	const amountOfPoints = getValues('points')
	const currencyAmount = getValues('amountSpent')
	const ruleDescription = getValues('description')

	return (
		<Card border="medium" elevated="small" data-testid="settings-card-view">
			<ContentCardViewMode>
				<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>
				{isEarning ? (
					<>
						<SectionStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.RULE')}</Paragraph>
								<Paragraph data-testid="rule-value">{rule}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">
									{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.AMOUNT_OF_POINTS')}
								</Paragraph>
								<Paragraph data-testid="amount-of-points-value">{amountOfPoints}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">
									{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.CURRENCY_AMOUNT')}
								</Paragraph>
								<Paragraph data-testid="currency-amount-value">{`R$ ${currencyAmount}`}</Paragraph>
							</ItemStyleViewMode>
							<ItemStyleViewMode>
								<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.STATUS')}</Paragraph>

								<StatusIndicator status="active" />
							</ItemStyleViewMode>
						</SectionStyleViewMode>
						<ItemStyleViewMode>
							<Paragraph weight="semibold">
								{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
							</Paragraph>
							<Paragraph data-testid="rule-description-value">{ruleDescription}</Paragraph>
						</ItemStyleViewMode>
					</>
				) : (
					<ItemStyleExcusionViewMode>
						<div style={{ width: '50%' }}>
							<Paragraph weight="semibold">
								{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
							</Paragraph>
							<Paragraph data-testid="exclusion-rule-description-value"> {ruleDescriptionExclusion} </Paragraph>
						</div>
						<ItemStyleViewMode>
							<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.STATUS')}</Paragraph>
							<Paragraph>
								<StatusIndicator status="active" />
							</Paragraph>
						</ItemStyleViewMode>
					</ItemStyleExcusionViewMode>
				)}
			</ContentCardViewMode>
		</Card>
	)
}
