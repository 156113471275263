import { loadExclusionRules } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'
import useCurrentSettings from '../settings/useCurrentSettings'

export function useExclusionRulesData(page: number = 0, pageSize: number = 25) {
	const settings = useCurrentSettings()
	const { vendorId } = settings

	const queryFn = async () => {
		const data = await loadExclusionRules(vendorId, page, pageSize)
		return {
			...data,
			content:
				data.content?.map((item) => ({
					...item,
					audienceName: item.audienceName || '-',
					description: item.description || '-',
					updatedAt: item.updatedAt || '-',
					status: item.status,
				})) || [],
		}
	}

	return useQuery({
		queryKey: ['exclusionRules', page, pageSize, vendorId],
		queryFn,
		staleTime: 5000,
	})
}
