import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
// import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { updateExclusionRule } from '../RulesService'
import { UpdateExclusionRuleDTO } from '../RulesService.types'

export const useUpdateExclusionRule = (): {
	isLoading: boolean
	putExclusionRule: (payload: UpdateExclusionRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()

	const { isLoading, mutate: putExclusionRule } = useMutation(
		(payload: UpdateExclusionRuleDTO) => {
			return updateExclusionRule({ ...payload, vendorId })
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: 'Exclusion Rule successfully updated.',
				})
				await queryClient.invalidateQueries(['exclusionRules'])

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: 'An error ocurred. Try again.',
				})
			},
		},
	)

	return {
		isLoading,
		putExclusionRule,
	}
}
