import React from 'react'
import { Button, Modal } from '@hexa-ui/components'
import { ParAddtolist } from '@hexa-ui/icons'
import { SkuModalValueType, useSkuModalContext } from '../../../../context/SkuModalContext'

interface SkuModalContinueProps {
	readonly onConfirm: (value: SkuModalValueType) => void
}

export function SkuModalContinue({ onConfirm }: SkuModalContinueProps) {
	const { addType, setAddType, skuModalValue } = useSkuModalContext()

	const handleConfirmMode = () => setAddType({ ...addType, continue: true })

	return (
		<Modal.Action>
			{!addType.continue ? (
				<Button
					data-testid="btn-continue"
					size="medium"
					variant="primary"
					disabled={!addType.type}
					onClick={handleConfirmMode}
				>
					Continue
				</Button>
			) : (
				<Button
					data-testid="btn-add"
					icon={() => <ParAddtolist size="large" />}
					leading
					size="medium"
					variant="primary"
					disabled={!skuModalValue.items.length}
					onClick={() => onConfirm(skuModalValue)}
				>
					Add
				</Button>
			)}
		</Modal.Action>
	)
}
