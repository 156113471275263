import React from 'react'
import { Table } from '@hexa-ui/components'
import { SkuListTableProps } from './SkuListTableV2.types'
import { SkuModal } from '../SkuModal/SkuModal'
import { useTableHandlers } from './hooks/useTableHandlers/useTableHandlers'
import { ToolbarExtra } from './Components/ToolbarExtra/ToolbarExtra'
import { useModal } from './hooks/useModal/useModal'

const SkuListTableV2: React.FC<SkuListTableProps> = (props) => {
	const { addSkuHandler, tableProps } = useTableHandlers({ ...props })
	const { isModalOpened, closeModalHandler, openModalHandler } = useModal()

	if (props?.isEditMode && props?.hasToolbarButton) {
		tableProps.toolbarExtra = () => <ToolbarExtra handleButton={openModalHandler} />
	}

	return (
		<>
			<SkuModal
				isModalOpened={isModalOpened}
				closeModal={closeModalHandler}
				onAddValues={(value) => addSkuHandler(value.items)}
			/>
			<Table {...tableProps} />
		</>
	)
}

SkuListTableV2.displayName = 'SkuListTableV2'

export default SkuListTableV2
