import React from 'react'
import { useTranslation } from 'react-i18next'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { Heading, Paragraph, Input } from '@hexa-ui/components'
import { Controller, useFormContext, Control } from 'react-hook-form'
import { ONLY_NUMBER_REGEX } from '@/utils/constants'
import { Card, Content, Header } from './SettingsCard.styles'
import { CreateEarningRuleFormData } from '../../Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { handleInputTypeNumber } from './SettingsCardUtils'
import { CreateExclusionRuleFormData } from '../../Pages/CreateExclusionPageRule/context/CreateExclusionListProvider/CreateExclusionListProvider.types'

interface SettingsCardProps {
	readonly isEarning: boolean
}

export function SettingsCard({ isEarning }: Readonly<SettingsCardProps>) {
	const { t } = useTranslation()
	const { control: earningControl, setValue: setEarningValue } = useFormContext<CreateEarningRuleFormData>()

	const { control: exclusionControl } = useFormContext<CreateExclusionRuleFormData>()

	const renderInput = ({
		name,
		label,
		placeholder,
	}: {
		name: 'points' | 'amountSpent'
		label: string
		placeholder: string
	}) => {
		return (
			<Controller
				name={name}
				control={earningControl}
				defaultValue={undefined}
				render={({ field: { value, ...field }, fieldState: { error } }) => (
					<Input
						errorText={error?.message}
						hasError={!!error?.message}
						label={label}
						placeholder={placeholder}
						width="100%"
						{...field}
						onChange={(e) => {
							const { isNumber, value: val } = handleInputTypeNumber(e.currentTarget.value)
							if (isNumber) {
								setEarningValue(name, val, {
									shouldValidate: true,
								})
							}
						}}
						value={ONLY_NUMBER_REGEX.test(`${value}`) ? value : ''}
					/>
				)}
			/>
		)
	}

	const renderDescription = ({ control }: { control: Control<any> }) => {
		return (
			<Controller
				name="description"
				control={control}
				defaultValue={undefined}
				render={({ field: { ...field }, fieldState: { error } }) => (
					<Input
						{...field}
						errorText={error?.message}
						hasError={!!error?.message}
						label={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
						placeholder={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_RULE_DESCRIPTION')}
						width="100%"
					/>
				)}
			/>
		)
	}

	return (
		<Card border="medium" elevated="small" data-testid="settings-card">
			<Content>
				<Header>
					<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>

					<Paragraph colortype="secondary">
						{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS_DESCRIPTION')}
					</Paragraph>
				</Header>
				{isEarning && (
					<GridContainer
						style={{ width: '100%', minHeight: '16px', gap: '16px', display: 'grid', gridTemplateColumns: 'auto auto' }}
					>
						<GridItem>
							{renderInput({
								label: t('rules:CREATE_EARNING_RULES.EARNING_SECTION.AMOUNT_OF_POINTS'),
								name: 'points',
								placeholder: t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_POINTS'),
							})}
						</GridItem>

						<GridItem>
							{renderInput({
								label: t('rules:CREATE_EARNING_RULES.EARNING_SECTION.CURRENCY_AMOUNT'),
								name: 'amountSpent',
								placeholder: t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_CURRENCY_AMOUNT'),
							})}
						</GridItem>
					</GridContainer>
				)}
				{renderDescription({ control: isEarning ? earningControl : exclusionControl })}
			</Content>
		</Card>
	)
}
