import React, { useEffect } from 'react'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EARNING_RULES } from '@/utils/constants'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import { useTranslation } from 'react-i18next'
import { useDeleteEarningRule } from '@/hooks/useDeleteEarningRule/useDeleteEarningRule'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useCreateEarningRule } from '@/services/rules/hooks/useCreateEarningRule'
import { useUpdateEarningRule } from '@/services/rules/hooks/useUpdateEarningRule'
import { useFormContext } from 'react-hook-form'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { EnuStatusRule } from '@/services/rules/RulesService.types'
import { TierMessage } from '../../Components/TierMessage/TierMessage'
import * as Style from './CreateEarningRule.styles'
import { Settings } from './components/Settings/Settings'
import { SkuList } from './components/SkuList/SkuList'
import { usePermissionScope } from './hooks/usePermissionScope/usePermissionScope'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import { useHandlersActionsButtons } from './hooks/useHandlersActionsButtons/useHandlersActionsButtons'
import { CreateEarningRuleFormData } from './context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'

const CreateEarningRule: React.FC = () => {
	const { t } = useTranslation()
	const actionsButtonsProps = useHandlersActionsButtons()
	const requiredScopes = [CREATE_EARNING_RULES]
	const hasGlobalManagerScope = useScopeAuthorization(requiredScopes)
	const basePath = '/membership/rules/earning-rule'
	const { setEditMode, isEditMode } = useEditMode(basePath, requiredScopes)
	usePermissionScope()
	const hasRuleId = getRuleIdFromPathname()
	const { postCreateEarningRule } = useCreateEarningRule()
	const { putEarningRule } = useUpdateEarningRule()
	const { deleteRule } = useDeleteEarningRule()

	const {
		handleSubmit,
		watch,
		getValues,
		formState: { isValid },
	} = useFormContext<CreateEarningRuleFormData>()

	const hasSkus = !!watch('items')?.length
	const status = getValues('status')

	const { showModal: showConfimationExclusionModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_EXCLUSION.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_EXCLUSION.CANCEL_LABEL'),
		title: t('rules:MODAL_EXCLUSION.TITLE'),
		body: t('rules:MODAL_EXCLUSION.BODY'),
		confirmVariant: 'destructive',
	})

	const { showModal: showCreateRuleModal } = useConfirmationModal({
		confirmLabel: 'Yes, create',
		cancelLabel: 'No, go back',
		title: 'Create rule?',
		body: 'Once created, this rule becomes active.',
	})

	const { showModal: showUpdateRuleModal } = useConfirmationModal({
		confirmLabel: 'Yes, save',
		cancelLabel: 'No, go back',
		title: 'Save changes?',
		body: 'Saved changes will apply to the edited rule.',
	})

	/* istanbul ignore next */
	const handleDeleteRule = async () => {
		const confirmexclusion = await showConfimationExclusionModal()

		if (confirmexclusion) {
			deleteRule()
		}
	}

	const handleEditRule = /* istanbul ignore next */ () => {
		/* istanbul ignore next */
		setEditMode()
	}

	const headerPayload = useCreateRulesHeaderPayload({
		handleDeleteRule,
		handleEditRule,
		hasGlobalManagerScope,
		isOnEditPage: isEditMode && !!hasRuleId,
		earningRule: {
			amountSpent: getValues('amountSpent'),
			points: getValues('points'),
			ruleId: getValues('ruleId') ?? '',
			status,
		},
	})

	useEffect(() => {
		if (getValues()?.ruleId) {
			setAppHeaderConfig(headerPayload)
		}

		const optionalButtonsCleanUp = (): void =>
			setAppHeaderConfig({
				optionalButtons: [],
			})

		return optionalButtonsCleanUp
	}, [getValues, headerPayload])

	const onSaveAsDraft = (formData: CreateEarningRuleFormData) => {
		const payload = { ...formData, status: EnuStatusRule.draft }

		if (hasRuleId) {
			putEarningRule({ ...payload, ruleId: hasRuleId })
			return
		}

		postCreateEarningRule({ ...payload })
	}

	const onSelectModalAction = async () => {
		const formData = getValues()
		const payload = { ...formData, status: EnuStatusRule.active }

		if (hasRuleId) {
			const isToUpdate = await showUpdateRuleModal()

			/* istanbul ignore else */
			if (isToUpdate) {
				putEarningRule({ ...payload, ruleId: hasRuleId })
			}
			return
		}
		const isToConfirm = await showCreateRuleModal()

		/* istanbul ignore else */
		if (isToConfirm) {
			postCreateEarningRule({ ...payload })
		}
	}

	return (
		<>
			<Style.Container>
				<PageLevelTemplate />
				<TierMessage tierMessagePath="rules:CREATE_EARNING_RULES.EARNING_DESCRIPTION" />
				<Settings />
				<SkuList />
			</Style.Container>

			<ActionButtonsWrapper
				{...actionsButtonsProps}
				isSubmitButtonDisabled={!(hasSkus && isValid)}
				isSaveAndExitButtonDisabled={!isValid}
				onActionSaveAndExit={status === EnuStatusRule.draft && isEditMode ? handleSubmit(onSaveAsDraft) : undefined}
				onActionSubmit={isEditMode ? onSelectModalAction : undefined}
				isGoBackButtonDisabled={!isEditMode ? undefined : true}
			/>
		</>
	)
}

export default CreateEarningRule
