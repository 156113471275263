import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { createEarningRule } from '../RulesService'
import { CreateEarningRuleDTO } from '../RulesService.types'

export const useCreateEarningRule = (): {
	isLoading: boolean
	postCreateEarningRule: (payload: CreateEarningRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { selectedTierKey } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()

	const { isLoading, mutate: postCreateEarningRule } = useMutation(
		(payload: CreateEarningRuleDTO) => {
			const skusWithVendorId = payload.items?.map((sku) => ({
				...sku,
				vendorId,
			}))

			payload.items = skusWithVendorId
			return createEarningRule({ ...payload, vendorId, tier: selectedTierKey })
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: 'Rule successfully saved as draft.',
				})
				await queryClient.invalidateQueries(['earningRules'])

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: 'An error ocurred. Try again.',
				})
			},
		},
	)

	return {
		isLoading,
		postCreateEarningRule,
	}
}
