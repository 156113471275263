import React from 'react'
import { Button, Modal } from '@hexa-ui/components'
import { useSkuModalContext } from '../../../../context/SkuModalContext'

interface SkuModalBackProps {
	readonly onClose: () => void
}

export function SkuModalBack({ onClose }: SkuModalBackProps) {
	const { setAddType, setSkuModalValue, setError, addType } = useSkuModalContext()

	const handleBack = () => {
		if (!addType.type || (addType.type && !addType.continue)) {
			onClose()
		} else {
			setAddType({ ...addType, continue: false })
			setSkuModalValue({ items: [], fileName: '' })
			setError('')
		}
	}

	return (
		<Modal.Cancel>
			<Button data-testid="btn-go-back" size="medium" variant="secondary" onClick={handleBack}>
				Go back
			</Button>
		</Modal.Cancel>
	)
}
