import React from 'react'
import { CREATE_EARNING_RULES } from '@/utils/constants'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { SettingsCard } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCard'
import { SettingsCardView } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardView'

export const Settings = () => {
	const requiredScopes = [CREATE_EARNING_RULES]
	const basePath = '/membership/rules/earning-rule'
	const { isEditMode } = useEditMode(basePath, requiredScopes)

	return isEditMode ? <SettingsCard isEarning /> : <SettingsCardView isEarning />
}
