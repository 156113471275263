import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { updateEarningRule } from '../RulesService'
import { UpdateEarningRuleDTO } from '../RulesService.types'

export const useUpdateEarningRule = (): {
	isLoading: boolean
	putEarningRule: (payload: UpdateEarningRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { selectedTierKey } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()

	const { isLoading, mutate: putEarningRule } = useMutation(
		(payload: UpdateEarningRuleDTO) => {
			const skusWithVendorId = payload.items?.map((sku) => ({
				...sku,
				vendorId,
			}))

			payload.items = skusWithVendorId
			return updateEarningRule({ ...payload, vendorId, tier: selectedTierKey })
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: 'Rule successfully edited.',
				})
				await queryClient.invalidateQueries(['earning-rule-by-id'])
				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: 'An error ocurred. Try again.',
				})
			},
		},
	)

	return {
		isLoading,
		putEarningRule,
	}
}
