import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useRuleDataTable from '@/routes/rules/hooks/useRuleDataTable'
import { BASE_ROUTE } from '@/utils/constants'
import { formatDate } from '@/utils/date'
import { useRuleSelectedStatus } from '@/context/RuleSelectedStatusContext/RuleSelectedStatusContext'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import GenericRulesList from '../components/GenericRuleList/GenericRuleList'
import { RenderStatusColumn } from '../components/GenericRuleList/components/customRender/StatusColumn/StatusColumn'

const EarningRulesList: React.FC = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const history = useHistory()
	const { dataRules, setRuleFilter } = useRuleDataTable()
	const { t } = useTranslation()
	const { setRuleSelectedStatus } = useRuleSelectedStatus()
	const isShowExclusionListEnabled = useFeatureToggleV2(FeatureToggle.SHOW_EXCLUSION_LIST)

	useEffect(() => {
		if (dataRules) setLoading(false)
	}, [dataRules])

	const handleRowClick = (ruleId: string, status: string) => {
		setRuleSelectedStatus(status ?? 'ACTIVE')

		if (isShowExclusionListEnabled) {
			history.push({
				pathname: `${BASE_ROUTE}/rules/earning-rule/${ruleId}`,
				search: status === 'DRAFT' ? '?action=edit' : '?action=view',
			})
		} else {
			history.push({
				pathname: `${BASE_ROUTE}/rules/${ruleId}`,
			})
		}
		localStorage.setItem('defaultValueTab', 'tab-defaultEarningRules')
	}

	const columns = useMemo(
		() => [
			{
				Header: t('rules:EARNING_RULES_TABLE.RULE'),
				accessor: 'nameColumn',
				disableSortBy: false,
				style: { width: '20%' },
			},
			{
				Header: t('common:POINTS'),
				accessor: 'points',
				disableSortBy: true,
				style: { width: '10%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.CURRENCY_AMOUNT'),
				accessor: 'currencyAmount',
				disableSortBy: true,
				style: { width: '10%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.SKUS'),
				accessor: 'skus.length',
				disableSortBy: true,
				style: { width: '5%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.EDITED_ON'),
				accessor: 'lastModified',
				disableSortBy: false,
				style: { width: '10%' },
				Cell: ({ value }: { value: Date }) => (value ? formatDate(value, 'DD MMMM, YYYY') : '-'),
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.STATUS'),
				accessor: 'status',
				disableSortBy: true,
				style: { width: '10%' },
				customRender: RenderStatusColumn,
			},
		],
		[t],
	)

	return (
		<GenericRulesList
			columns={columns}
			data={dataRules}
			loading={loading}
			description="rules:EARNING_RULES_TABLE.EARNING_RULES_DESCRIPTION"
			search
			onSearchChange={(event) => setRuleFilter(event)}
			onRow={(rowData) => ({
				onClick: () => handleRowClick(rowData.ruleId, rowData?.status),
				style: { cursor: 'pointer' },
			})}
		/>
	)
}

export default EarningRulesList
