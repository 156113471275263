import React from 'react'
import { Heading, Paragraph } from '@hexa-ui/components'
import { AudienceSelector } from '@dlb-shared-business-components/audience'
import useGetUserInfo from '@/hooks/useInfo/useGetUserInfo'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useTranslation } from 'react-i18next'
import { AudienceSelectorTypes } from '@/services/rules/RulesService.types'
import { useFormContext } from 'react-hook-form'
import { CardAudienceExclusionEditData } from './AudienceExclusionEditData.styles'
import { CreateExclusionRuleFormData } from '../../../context/CreateExclusionListProvider/CreateExclusionListProvider.types'

export const AudienceExclusionEditData = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const { getValues, setValue } = useFormContext<CreateExclusionRuleFormData>()

	const audienceId = getValues('audienceId')

	const handleSelectAudience = (audienceSelected: AudienceSelectorTypes) => {
		setValue('audienceId', audienceSelected.audienceId)
	}

	return (
		<CardAudienceExclusionEditData border="medium" elevated="small" data-testid="exclusion-rule-page-audience">
			<>
				<Heading id="audience-title" size="H3">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.TITLE')}
				</Heading>
				<Paragraph colortype="secondary" weight="medium">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.INFO_TEXT')}
				</Paragraph>
			</>

			<AudienceSelector
				data-testid="audience-selector"
				country={userInfo?.zone ?? ''}
				vendorId={vendorId}
				onSelectAudience={handleSelectAudience}
				selectedAudienceId={audienceId ?? undefined}
				isSearchable
				enableCreation
				audienceCreatorProps={{ popToggleIsEnabled: false }}
				profile="membership"
			/>
		</CardAudienceExclusionEditData>
	)
}
