import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@hexa-ui/components'
import SkuForm from '@/domains/sku/SkuForm'
import { CreateEarningRuleFormData } from '@/routes/rules/RulesRedesign/Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { useFormContext } from 'react-hook-form'
import { SkuRuleItem } from '@/services/rules/RulesService.types'
import { useHistory } from 'react-router-dom'
import { SkuPreview } from '../../Components/customRender/SkuPreview/SkuPreview'
import { SkuDelete } from '../../Components/customRender/SkuDelete/SkuDelete'
import { EmptyState } from '../../Components/EmptyState/EmptyState'
import { UseTableHandlersProps } from './useTableHandlers.types'

export const useTableHandlers = ({ hasTableSearch, isEditMode }: UseTableHandlersProps) => {
	const { t } = useTranslation()
	const [skuList, setSkuList] = useState<SkuForm[]>([])
	const {
		getValues,
		setValue,
		control: { defaultValuesRef },
		reset,
	} = useFormContext<CreateEarningRuleFormData>()
	const history = useHistory()

	const skusFromFormContext = useMemo(() => getValues('items') ?? [], [getValues])

	const toSkusForm = (items: SkuRuleItem[]): SkuForm[] => {
		return items?.map((item: SkuRuleItem) => ({
			id: item.itemId,
			name: item.sku,
			itemId: item.vendorItemId,
			sku: item.sku,
		}))
	}

	useEffect(() => {
		setSkuList(() => toSkusForm(skusFromFormContext))
	}, [skusFromFormContext])

	const addSkuHandler = useCallback(
		(newSkus: SkuForm[]) => {
			if (newSkus) {
				setSkuList((prev) => {
					const existingSkuIds = new Set(prev.map((sku) => sku.id))
					const uniqueNewSkus = newSkus.filter((sku) => !existingSkuIds.has(sku.id))
					return [...uniqueNewSkus, ...prev]
				})
			}
		},
		[setSkuList],
	)

	const handleRemoveSku = useCallback(
		(id: string) => {
			setSkuList((prev) => prev.filter((sku) => sku.id !== id))
		},
		[setSkuList],
	)

	const columns = useMemo(
		() => [
			{
				Header: 'Preview',
				accessor: 'image',
				disableSortBy: true,
				customRender: SkuPreview,
				style: { width: '10%' },
			},
			{
				Header: t('rules:SKUS.LIST.COLUMN_PRODUCT'),
				accessor: 'name',
				disableSortBy: true,
				style: { width: '44%' },
			},
			{
				Header: t('rules:SKUS.LIST.COLUMN_SKU'),
				accessor: 'sku',
				disableSortBy: true,
				style: { width: '44%' },
			},
			{
				Header: '',
				accessor: 'delete',
				disableSortBy: true,
				style: { width: '2%' },
				customRender: (_, rowData: SkuForm) =>
					isEditMode ? <SkuDelete rowData={rowData} handleClick={handleRemoveSku} /> : null,
			},
		],
		[t, handleRemoveSku, isEditMode],
	)

	useEffect(() => {
		const toForm: SkuRuleItem[] = skuList.map(({ id, itemId, sku }) => ({
			itemId: id,
			sku,
			vendorItemId: itemId,
		}))

		setValue('items', toForm)
	}, [skuList, setValue])

	useEffect(() => {
		const unlisten = history.listen(() => {
			const { items } = defaultValuesRef.current
			if (items) {
				const validItems = items.filter(
					(item): item is SkuRuleItem => !!item.vendorItemId && !!item.sku && !!item.itemId,
				)
				setSkuList(toSkusForm(validItems))
			}
			reset()
		})
		return () => {
			unlisten()
		}
	}, [history, defaultValuesRef, reset])

	const tableProps: TableProps<SkuForm> = {
		columns,
		data: skuList,
		search: hasTableSearch,
		searchPlaceholder: t('rules:SKUS.LIST.SEARCH'),
		emptyMessage: <EmptyState />,
	}

	return {
		tableProps,
		addSkuHandler,
		handleRemoveSku,
	}
}
