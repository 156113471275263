import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type ToggleEditMode = (isEdit: boolean) => void

interface UseEditModeProps {
	isEditMode: boolean
	setEditMode: () => void
	setViewMode: () => void
}

const useEditMode = (basePath: string, requiredScopes: string[]): UseEditModeProps => {
	const history = useHistory()
	const location = useLocation()

	const action = new URLSearchParams(location.search).get('action')
	const isEditMode = action === 'edit'
	const isViewMode = action === 'view' || action === null

	const hasRequiredScopes = useScopeAuthorization(requiredScopes)

	const toggleEditMode: ToggleEditMode = useCallback(
		(isEdit: boolean) => {
			const url = new URL(window.location.href)
			if (isEdit && !hasRequiredScopes) {
				url.searchParams.set('action', 'view')
			} else {
				url.searchParams.set('action', isEdit ? 'edit' : 'view')
			}
			history.push(url.pathname + url.search)
		},
		[history, hasRequiredScopes],
	)

	const urlPath = location.pathname
	const isCreateBasePath = urlPath.includes(basePath)
	const hasIdInPath = new RegExp(`${basePath}/[^/]+$`).test(urlPath)

	const determineEditMode = (): boolean => {
		if (isCreateBasePath && !hasIdInPath) {
			return true
		}
		if (isViewMode) {
			return false
		}
		return isEditMode && hasRequiredScopes
	}

	useEffect(() => {
		if (isEditMode && !hasRequiredScopes) {
			toggleEditMode(false)
		}
	}, [isEditMode, hasRequiredScopes, toggleEditMode])

	return {
		isEditMode: determineEditMode(),
		setEditMode: () => toggleEditMode(true),
		setViewMode: () => toggleEditMode(false),
	}
}

export default useEditMode
