import { createStyles, makeStyles } from '@material-ui/core/styles'

const RedeemableItemsTableStyle = makeStyles(() =>
	createStyles({
		mainTable: {
			color: 'var(--colors-interfaceLabelPrimary)',
			width: '100%',

			boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
			borderRadius: '16px',

			// Fix: override Table header fixed height
			'& > div > div:first-child': {
				height: 'auto',
				padding: 'var(--space-6)',

				'& > [data-testid="search-field-wrapper"]': {
					flex: 1,
					marginRight: 'var(--space-12)',
					maxWidth: '480px',
				},
			},

			// Fix: Override table content fixed height
			'& tr > td > div': {
				height: 'auto',
			},
		},
		emptyMessage: {
			margin: 'var(--space-24) 0',
		},
		toolbarExtra: {
			alignItems: 'center',
			padding: 0,
		},
	}),
)

export default RedeemableItemsTableStyle
