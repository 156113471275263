import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
// import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { createExclusionRule } from '../RulesService'
import { CreateExclusionRuleDTO } from '../RulesService.types'

export const useCreateExclusionRule = (): {
	isLoading: boolean
	postExclusionRule: (payload: CreateExclusionRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()

	const { isLoading, mutate: postExclusionRule } = useMutation(
		(payload: CreateExclusionRuleDTO) => {
			return createExclusionRule({ ...payload, vendorId })
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: 'Exclusion Rule successfully saved as draft.',
				})
				await queryClient.invalidateQueries(['exclusionRules'])

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: 'An error ocurred. Try again.',
				})
			},
		},
	)

	return {
		isLoading,
		postExclusionRule,
	}
}
