import { styled } from '@hexa-ui/theme'
import { Card as _Card } from '@hexa-ui/components'

export const Content = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	gap: 30,
})

export const Header = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	gap: 5,
})

export const ContentCardViewMode = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	gap: '30px',
})

export const SectionStyleViewMode = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
})

export const ItemStyleViewMode = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
})

export const ItemStyleExcusionViewMode = styled('div', {
	display: 'flex',
	gap: '5px',
})

export const Card = styled(_Card, {
	gap: '16px',
	padding: '32px',
})
