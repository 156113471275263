import { SettingsCard } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCard'
import { SettingsCardView } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardView'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EXCLUSION_LIST } from '@/utils/constants'
import React from 'react'

export const SettingsExclusion = () => {
	const requiredScopes = [CREATE_EXCLUSION_LIST]
	const basePath = '/membership/rules/exclusion-rule'
	const { isEditMode } = useEditMode(basePath, requiredScopes)

	return isEditMode ? <SettingsCard isEarning={false} /> : <SettingsCardView isEarning={false} />
}
