import { getEarningRuleById } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import useCurrentSettings from '../settings/useCurrentSettings'

export const useGetEarningRuleById = () => {
	const { selectedTierKey } = useStore(GlobalStore)
	const settings = useCurrentSettings()
	const { vendorId } = settings

	const ruleIds = getRuleIdFromPathname()

	const { data, isLoading } = useQuery(
		['earning-rule-by-id', vendorId, selectedTierKey, ruleIds],
		() =>
			getEarningRuleById({
				tier: selectedTierKey,
				vendorId,
				ruleIds,
			}),
		{
			enabled: !!ruleIds,
		},
	)

	return {
		data: data?.content?.[0],
		isLoading,
	}
}
