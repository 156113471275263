import React, { useCallback } from 'react'
import { Alert, Button, Dialog, Heading, SearchField, EmptyState as HexaEmptyState } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import {
	$createRedeemableItemStore,
	CreateRedeemableItemState,
	CreateRedeemableItemStoreEvents,
} from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { useStore } from 'effector-react'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores/redeemableItems/CriticalPricePerPointStoreV2'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useAnalyticsServiceEventRedeemableCreated } from '@/hooks/analyticsService/useAnalyticsServiceEventRedeemableCreated'
import { useFormContext } from 'react-hook-form'
import { CreateRedeemableItemUseCase } from '@/usecase'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import { ButtonInteraction, ScreenName } from '@/domains'
import { unformat } from '@/utils/currencyHelper'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useAnalyticsServiceEventTableSearched } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSearched'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import { EmptyState } from './EmptyState/EmptyState'
import useStyles from './AddRedeemableDialogStyle'
import { RedeemableItemCell } from '../RedeemableItem/RedeemableItemCell'
import { ItemCellLoader } from '../CreateRedeemableItem/content/Loader/ItemCellLoader'
import AddRedeemableItemFormProvider from './Form/AddRedeemableItemFormProvider'

interface AddRedeemableDialogProps {
	isOpened: boolean
	trigger?: React.ReactNode
}

export const AddRedeemableDialog: React.FC<AddRedeemableDialogProps> = ({ isOpened, trigger }) => {
	const css = useStyles()
	const { t } = useTranslation()

	const state = useStore($createRedeemableItemStore)
	const userInfo = useGetUserInfo()
	const { sendAnalyticsServiceTableSearched } = useAnalyticsServiceEventTableSearched()

	const minCpp = useFormatCurrency(state.item?.cppLimits?.min)
	const maxCpp = useFormatCurrency(state.item?.cppLimits?.max)
	const itemName = `input-ppp-${state.item?.id}`
	const variantNames = state.variants?.map((variant) => `input-ppp-${variant.id}`) || []
	const formFields = [itemName, ...variantNames]

	const onDialogOpenChangeHandler = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: ButtonInteraction.Cancel,
			button_name: ButtonInteraction.Cancel,
			screen_name: ScreenName.CreateRedeemable,
			...userInfo,
		})

		return isOpened ? CreateRedeemableItemStoreEvents.reset() : CreateRedeemableItemStoreEvents.onOpen()
	}, [isOpened, userInfo])

	const Title = <Heading size="H3">{t('redeemable:ADD.TITLE')}</Heading>

	const shouldRenderEmptyState = !state.isSearching && !state.item && state.atInitialState
	const shouldRenderNotFound = !state.isSearching && !state.item && !state.atInitialState

	const handleSearch = useCallback(
		(value: string, event: React.FormEvent) => {
			event.preventDefault()
			CreateRedeemableItemUseCase.onSearch(value, true)
			sendAnalyticsServiceTableSearched({
				searchQuery: value,
				contentType: 'Create Redeemable',
				tableName: ' Create Redeemable - SKU',
				screenName: ScreenName.CreateRedeemable,
			})
		},
		[sendAnalyticsServiceTableSearched],
	)

	return (
		<div className={css.container} data-testid="add-redeemable-dialog">
			<AddRedeemableItemFormProvider formFields={formFields}>
				<Dialog.Root
					closeButton
					actions={<Actions {...state} />}
					title={Title}
					trigger={trigger}
					open={isOpened}
					onOpenChange={onDialogOpenChangeHandler}
				>
					<p>{t('redeemable:ADD.DESCRIPTION')}</p>
					<SearchField.Root onSearch={handleSearch} placeholder={t('redeemable:SEARCH_PLACEHOLDER')} />

					<div className={css.emptyStateWrapper}>
						{shouldRenderEmptyState && (
							<div data-testid="add-redeemable-dialog-empty-state">
								<EmptyState />
							</div>
						)}

						{shouldRenderNotFound && (
							<div className={css.noResultWrapper}>
								<HexaEmptyState.SectionLevel
									description={t('redeemable:CREATE.ITEM_NOT_FOUND.DESCRIPTION')}
									ilustrationName="beesAlert"
								/>
							</div>
						)}
					</div>

					<div className={css.wrapperItem}>
						{state.isSearching && <ItemCellLoader />}

						{!state.isSearching && state.item && <RedeemableItemCell {...state.item} />}
					</div>

					{!state.isSearching && state.item && (
						<Alert
							message={
								state.item?.cppLimits
									? t('redeemable:ADD.COST_PER_POINT_RANGE', { minCpp, maxCpp })
									: t('redeemable:ADD.COST_PER_POINT_WITHOUT_RANGE')
							}
							type="warning"
							className={css.warning}
						/>
					)}

					{!state.isSearching &&
						state.variants.length > 0 &&
						state.variants.map((variant) => (
							<div key={variant.id}>
								<RedeemableItemCell {...variant} isVariant />
								<Alert
									key={variant.id}
									message={
										variant.cppLimits
											? t('redeemable:ADD.COST_PER_POINT_RANGE', { minCpp, maxCpp })
											: t('redeemable:ADD.COST_PER_POINT_WITHOUT_RANGE')
									}
									type="warning"
									className={css.warning}
								/>
							</div>
						))}

					<Alert
						className={css.alert}
						type="error"
						message={t('ERROR_MESSAGE.GENERIC_ERROR')}
						style={{ display: state.errorOnPublish || state.errorOnItems ? 'flex' : 'none' }}
					/>
				</Dialog.Root>
			</AddRedeemableItemFormProvider>
		</div>
	)
}

const Actions = ({ item, errorOnPublish, isPublishing, variants }: CreateRedeemableItemState) => {
	const css = useStyles()
	const { t } = useTranslation()

	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { sendAnalyticsServiceRedeemableCreated } = useAnalyticsServiceEventRedeemableCreated()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const invalidPricePerPoint = isDirty && !isValid

	const onSubmit = useCallback(
		async (redeebleItens: RedeemableItem[]) => {
			try {
				await CreateRedeemableItemUseCase.publish({ redeemables: redeebleItens }, true)

				if (redeebleItens[0]) {
					sendAuditLogRedeemables({
						pricePerPoint: redeebleItens[0].pricePerPoint,
						SKU: redeebleItens[0].vendorItemId,
						operation: IAuditOperations.INSERT,
					})
					sendAnalyticsServiceRedeemableCreated({
						pricePerPoint: redeebleItens[0].pricePerPoint!,
						SKU: redeebleItens[0].vendorItemId,
						skuName: redeebleItens[0].itemName,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			} catch (error) {
				if (error instanceof Error) {
					sendAnalyticsServiceEventError({
						failureReason: error.message,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			}
		},
		[sendAuditLogRedeemables, sendAnalyticsServiceRedeemableCreated, sendAnalyticsServiceEventError],
	)

	const buttonAction = useCallback(
		(pricePerPoints: { [key: string]: string }) => {
			const redeebleItens = [item as RedeemableItem, ...variants]

			redeebleItens.forEach((redeebleItem) => {
				if (pricePerPoints[`input-ppp-${redeebleItem.id}`]) {
					redeebleItem.pricePerPoint = unformat(pricePerPoints[`input-ppp-${redeebleItem.id}`])
				}
			})

			if (item) CreateRedeemableItemStoreEvents.setItem(redeebleItens[0])

			setTimeout(
				() =>
					CriticalPricePerPointConfigurationStoreEvents.onOpen({
						submit: () => {
							onSubmit(redeebleItens)
						},
						close: () => CreateRedeemableItemStoreEvents.onClose(),
						skuPricePerPoint: redeebleItens[0].pricePerPoint!,
					}),
				MODAL_CHANGE_DELAY,
			)
		},
		[item, variants, onSubmit],
	)

	return (
		<div className={css.actionContainer}>
			<Dialog.Close>
				<Button data-testid="add-redeemable-dialog-cancel-button" size="medium" variant="secondary">
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="add-redeemable-dialog-publish-button"
				size="medium"
				variant="primary"
				disabled={!item || invalidPricePerPoint}
				onClick={handleSubmit(buttonAction)}
				isLoading={isPublishing}
			>
				{errorOnPublish ? t('BUTTON.TRY_AGAIN') : t('BUTTON.ADD')}
			</Button>
		</div>
	)
}
