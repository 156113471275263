import React from 'react'
import { Bullet, Container, Text } from './settingsCardStatus.styles'

interface StatusIndicatorProps {
	status: 'active' | 'inactive' | 'pending' | 'disabled'
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
	return (
		<Container state={status}>
			<Bullet state={status} fr={undefined} size="tiny" />
			<Text state={status}>{status}</Text>
		</Container>
	)
}

export default StatusIndicator
