import { useEffect } from 'react'
import { useNavigation } from '@/hooks'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { CREATE_EARNING_RULES } from '@/utils/constants'

export const usePermissionScope = (): void => {
	const { goToRoutePath } = useNavigation()

	const userScopesCreateEarningRules = useScopeAuthorization([CREATE_EARNING_RULES])

	useEffect(() => {
		if (!userScopesCreateEarningRules) goToRoutePath('/rules')
	}, [userScopesCreateEarningRules, goToRoutePath])
}
