import React, { useEffect } from 'react'
import { Paragraph } from '@hexa-ui/components'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EARNING_RULES, CREATE_EXCLUSION_LIST } from '@/utils/constants'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import { useDeleteExclusionRule } from '@/hooks/useDeleteExclusionRule/useDeleteExclusionRule'
import { useFormContext } from 'react-hook-form'
import { EnuStatusRule } from '@/services/rules/RulesService.types'
import { useCreateExclusionRule } from '@/services/rules/hooks/useCreateExclusionRule'
import { useUpdateExclusionRule } from '@/services/rules/hooks/useUpdateExclusionRule'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import AudienceExclusion from './components/AudienceExclusion/AudienceExclusion'
import { SettingsExclusion } from './components/SettingsExclusion/SettingsExclusion'
import { useHandlersExclusionActionsButtons } from './hooks/useHandlersExclusionActionsButtons/useHandlersExclusionActionsButtons'
import { usePermissionScope } from './hooks/usePermissionScope/usePermissionScope'
import { createExclusionBreadcrumb } from './utils/createExclusionBreadcrumb'
import { CreateExclusionRuleFormData } from './context/CreateExclusionListProvider/CreateExclusionListProvider.types'
import * as Styles from './CreateExclusionPageRule.styles'

const CreateExclusionPageRule: React.FC = () => {
	usePermissionScope()
	const { t } = useTranslation()
	const { currentTier } = useCurrentTier()
	const requiredScopes = [CREATE_EXCLUSION_LIST]
	const hasGlobalManagerScope = useScopeAuthorization(requiredScopes)
	const actionsButtonsProps = useHandlersExclusionActionsButtons()
	const hasNotGlobalManagerScope = !useScopeAuthorization([CREATE_EARNING_RULES])
	const { postExclusionRule } = useCreateExclusionRule()
	const { putExclusionRule } = useUpdateExclusionRule()
	const hasRuleId = getRuleIdFromPathname()

	const basePath = '/membership/rules/exclusion-rule'
	const { isEditMode, setEditMode } = useEditMode(basePath, requiredScopes)

	const {
		getValues,
		watch,
		formState: { isValid },
	} = useFormContext<CreateExclusionRuleFormData>()

	const status = watch('status')
	const exclusionRuleId = watch('ruleId')
	const hasAudienceId = !!watch('audienceId')

	const { showModal: showConfimationExclusionModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_EXCLUSION.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_EXCLUSION.CANCEL_LABEL'),
		title: t('rules:MODAL_EXCLUSION.TITLE'),
		body: t('rules:MODAL_EXCLUSION.BODY'),
		confirmVariant: 'destructive',
	})
	const { deleteRule } = useDeleteExclusionRule()
	/* istanbul ignore next */
	const handleDeleteRule = async () => {
		const confirmexclusion = await showConfimationExclusionModal()

		if (confirmexclusion) {
			deleteRule()
		}
	}

	const handleEditRule = () => {
		setEditMode()
	}

	const headerPayload = useCreateRulesHeaderPayload({
		handleDeleteRule,
		handleEditRule,
		hasGlobalManagerScope,
		isOnEditPage: isEditMode && !!hasRuleId,
		exclusionRule: { ruleId: exclusionRuleId, status },
	})

	useEffect(() => {
		if (hasRuleId) {
			setAppHeaderConfig(headerPayload)
		}
		if (hasNotGlobalManagerScope && !hasRuleId) {
			setAppHeaderConfig({ breadcrumbConfig: createExclusionBreadcrumb })
		}
		const optionalButtonsCleanUp = (): void =>
			setAppHeaderConfig({
				optionalButtons: [],
			})

		return optionalButtonsCleanUp
	}, [hasNotGlobalManagerScope, headerPayload, hasRuleId])

	const handleSaveAsDraft = () => {
		const payload = getValues()

		if (hasRuleId) {
			putExclusionRule({ ...payload, ruleId: hasRuleId, status: EnuStatusRule.draft })
			return
		}

		postExclusionRule({ ...payload, status: EnuStatusRule.draft })
	}

	const handleSubmit = () => {
		const payload = getValues()

		if (hasRuleId) {
			putExclusionRule({ ...payload, ruleId: hasRuleId, status: EnuStatusRule.active })
			return
		}

		postExclusionRule({ ...payload, status: EnuStatusRule.active })
	}

	return (
		<Styles.Container>
			<Paragraph colortype="secondary" weight="medium">
				{t('rules:CREATE_EXCLUSION_RULES.EXCLUSION_DESCRIPTION', { tier: currentTier?.label })}
			</Paragraph>
			<SettingsExclusion />
			<AudienceExclusion />
			<ActionButtonsWrapper
				{...actionsButtonsProps}
				isSaveAndExitButtonDisabled={!isValid}
				isSubmitButtonDisabled={!(hasAudienceId && isValid)}
				onActionSaveAndExit={status === EnuStatusRule.draft && isEditMode ? handleSaveAsDraft : undefined}
				onActionSubmit={isEditMode ? handleSubmit : undefined}
				isGoBackButtonDisabled={!isEditMode ? undefined : true}
			/>
		</Styles.Container>
	)
}

export default CreateExclusionPageRule
