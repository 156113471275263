import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import { $createRedeemableItemStore } from '@/stores'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { Grid, TextButton } from '@hexa-ui/components'
import { REDEEMABLE_DEFAULT_CPP, REDEEMABLE_SKU_CPP } from '@/utils/constants'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { syncCombo } from '@/services/redeemables/RedeemablesService'
import { Sync } from '@material-ui/icons'
import { ViewState } from '@/domains/redeemable/ViewState'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { CurrentTransactionStatusEnum } from '@/domains/redeemable/RedeemableItem'
import { $defaultCppStore } from '@/stores/redeemableItems/DefaultCPPStore'
import { useRedeemableItemsList } from './hooks/useRedeemableItemsList'
import { useRedeemableItemActions } from './hooks/useRedeemableItemActions'
import { RedeemableItemsTable } from './components/RedeemableItemsTable/RedeemableItemsTable'
import { EditRedeemableItem } from '../EditRedeemableItemV2/EditRedeemableItem'
import RedeemableItemsListStyle from './RedeemableItemsListStyle'
import { ExportRedeemableItemButton } from './components/ExportRedeemableItemButton/ExportRedeemableItemButton'
import { RedeemableItemsEmptyState } from './components/RedeemableItemsEmptyState/RedeemableItemsEmptyState'
import { AddRedeemableDialog } from '../AddRedeemableDialog/AddRedeemableDialog'
import { CreateRedeemableItemButton } from './components/CreateRedeemableItemButton/CreateRedeemableItemButton'
import { EditDefaultCppDialog } from '../EditDefaultCPPDialog/EditDefaultCPPDialog'
import { EditDefaultCppCard } from '../EditDefaultCppCard/EditDefaultCppCard'
import { CancelRequestDialog } from '../CancelRequestDialog/CancelRequestDialog'
import { useDefaultCpp } from '../../hooks/useDefaultCpp'

export const RedeemableItemsList = (props: HTMLAttributes<HTMLDivElement>) => {
	const { reloadAtTime } = useStore($defaultCppStore)
	const { isLoading, hasError, data, ...useProps } = useRedeemableItemsList()
	const { genericToastError } = useRedeemableItemActions()
	const toast = useToast()
	const { t } = useTranslation()
	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [viewState, setViewState] = useState(ViewState.LOADING)
	const { isOpened } = useStore($createRedeemableItemStore)
	const is3pd = useFeatureToggleV2(FeatureToggle.REDEEMABLE_SYNC_3PD)
	const [isEditDefaultCppOpened, setIsEditDefaultCppOpened] = useState(false)
	const [isCancelRequestOpened, setIsCancelRequestOpened] = useState(false)
	const { loadDefaultCppData, defaultCppData, isDefaultCppLoading } = useDefaultCpp()

	const css = RedeemableItemsListStyle()

	const hasSKUCPPPermission = useScopeAuthorization([REDEEMABLE_SKU_CPP])
	const hasRedeemablesDefaultCPPPermission = useScopeAuthorization([REDEEMABLE_DEFAULT_CPP])

	const canEditSKU = hasRedeemablesDefaultCPPPermission || hasSKUCPPPermission
	const isDefaultCppProcessing = defaultCppData?.update.status === CurrentTransactionStatusEnum.PROCESSING

	useEffect(() => {
		loadDefaultCppData()
	}, [reloadAtTime, loadDefaultCppData])

	const notifyUser = (status: number) => {
		const messageKey = status === 200 || status === 201 ? 'redeemable:REFRESH.SUCCESS' : 'redeemable:REFRESH.FAIL'

		toast.notify({
			type: status === 200 || status === 201 ? TypeToast.SUCCESS : TypeToast.ERROR,
			message: t(messageKey),
		})
	}

	const syncRedeemClick = async () => {
		try {
			const { status } = await syncCombo()
			notifyUser(status)
		} catch {
			notifyUser(0)
		}
	}

	const handleOpenDialog = () => {
		if (isDefaultCppProcessing) {
			setIsCancelRequestOpened(true)
		} else {
			setIsEditDefaultCppOpened(true)
		}
	}

	useEffect(() => {
		if (isLoading) {
			setViewState(ViewState.LOADING)
			return
		}

		if (hasError) {
			setViewState(ViewState.ERROR)
			return
		}

		if (isFirstLoad && data.length <= 0) {
			setViewState(ViewState.EMPTY)
			return
		}

		setIsFirstLoad(false)
		setViewState(ViewState.DONE)
	}, [isLoading, hasError, data, viewState, isFirstLoad])

	useEffect(() => {
		if (viewState !== ViewState.ERROR) {
			return
		}

		genericToastError()
	}, [viewState, genericToastError])

	useEffect(() => {
		loadDefaultCppData()
	}, [loadDefaultCppData])

	const renderDefaultCpp = () => (
		<>
			<EditDefaultCppCard
				onEdit={handleOpenDialog}
				newDefaultCppContent={defaultCppData}
				loading={isDefaultCppLoading}
			/>
			<EditDefaultCppDialog
				open={isEditDefaultCppOpened}
				onClose={() => setIsEditDefaultCppOpened(false)}
				onUpdate={() => {
					setIsEditDefaultCppOpened(false)
					toast.notify({
						type: TypeToast.SUCCESS,
						message: t('redeemable:UPDATE_CPP.TOAST_SUCCESS'),
					})
				}}
			/>
			<CancelRequestDialog
				isOpened={isCancelRequestOpened}
				requestInfo={{
					id: defaultCppData?.id ?? '',
					pricePerPoint: defaultCppData?.defaultCpp ?? 0,
					updatedPricePerPoint: defaultCppData?.newDefaultCpp ?? 0,
					type: 'DEFAULT',
				}}
				onClose={() => setIsCancelRequestOpened(false)}
			/>
		</>
	)

	if (isFirstLoad && [ViewState.LOADING, ViewState.EMPTY, ViewState.ERROR].includes(viewState)) {
		return (
			<Grid.Item data-testid="redeemable-items-empty-state" className={css.mainWrapper} {...props}>
				{renderDefaultCpp()}
				<RedeemableItemsEmptyState viewState={viewState} canCreateSKU={canEditSKU} />
			</Grid.Item>
		)
	}

	return (
		<Grid.Item data-testid="redeemable-items-list-v2" className={css.mainWrapper} {...props}>
			<EditRedeemableItem />
			<Grid.Item className={css.headerWrapper}>
				<ExportRedeemableItemButton />
				{canEditSKU && <AddRedeemableDialog isOpened={isOpened} trigger={<CreateRedeemableItemButton />} />}
			</Grid.Item>
			{renderDefaultCpp()}
			<RedeemableItemsTable viewState={viewState} data={data} {...useProps} />
			{is3pd && (
				<TextButton
					onClick={syncRedeemClick}
					icon={Sync}
					iconPosition="leading"
					style={{
						marginTop: '-41px',
						alignSelf: 'flex-start',
						marginLeft: '14px',
					}}
					data-testid="redeemable-items-list-refresh-button"
				>
					{t('redeemable:REFRESH.BUTTON')}
				</TextButton>
			)}
		</Grid.Item>
	)
}
