import { useMemo } from 'react'
import { Edit2, Trash2 } from '@admin-portal-shared-components/icons'
import { AppHeaderConfigPayload, OptionalButton } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import * as currencyHelper from '@/utils/currencyHelper'

interface UseCreateRulesHeaderPayloadProps {
	handleDeleteRule: () => Promise<void>
	handleEditRule: () => void
	hasGlobalManagerScope: boolean
	earningRule?: { points?: number; amountSpent?: number; ruleId?: string; status: string }
	exclusionRule?: { ruleId?: string; status: string }
	isOnEditPage: boolean
}

const homePath = '/membership'

const rulesListBreadcrumbItem = {
	label: 'Rules List',
	path: '/membership/rules',
	isCurrentPage: false,
}

function getEarningRulePath(earningRuleId: string, actionType: string): string {
	return `/membership/rules/earning-rule/${earningRuleId}?action=${actionType}`
}

function getExclusionRulePath(exclusionRuleId: string, actionType: string): string {
	return `/membership/rules/exclusion-rule/${exclusionRuleId}?action=${actionType}`
}

const useCreateRulesHeaderPayload = ({
	handleDeleteRule,
	handleEditRule,
	hasGlobalManagerScope,
	earningRule,
	exclusionRule,
	isOnEditPage,
}: UseCreateRulesHeaderPayloadProps): AppHeaderConfigPayload => {
	const { t } = useTranslation()

	const { status: earningStatus, points, amountSpent, ruleId: earningRuleId } = earningRule || {}
	const { status: exclusionStatus, ruleId: exclusionRuleId } = exclusionRule || {}

	const pageTitle = useMemo(() => {
		if (isOnEditPage) {
			if (earningStatus === 'DRAFT') {
				return 'Earning'
			}
			if (exclusionStatus === 'DRAFT') {
				return 'Exclusion'
			}
			return 'Edit rule'
		}

		if (points && amountSpent) {
			return t('rules:EARNING_NAME', {
				point: points,
				amountSpent: currencyHelper.format(amountSpent),
			})
		}

		return 'Exclusion'
	}, [amountSpent, earningStatus, exclusionStatus, isOnEditPage, points, t])

	const breadcrumbItems = useMemo(() => {
		if (earningRuleId && earningStatus === 'DRAFT') {
			return [
				rulesListBreadcrumbItem,
				{ label: 'Earning', path: getEarningRulePath(earningRuleId, 'edit'), isCurrentPage: true },
			]
		}

		if (exclusionStatus === 'DRAFT' && exclusionRuleId) {
			return [
				rulesListBreadcrumbItem,
				{ label: 'Exclusion', path: getExclusionRulePath(exclusionRuleId, 'edit'), isCurrentPage: true },
			]
		}

		if (earningRuleId) {
			const earningRuleDetailBreadcrumb = {
				label: t('rules:EARNING_NAME', {
					point: points,
					amountSpent: currencyHelper.format(amountSpent),
				}),
				path: getEarningRulePath(earningRuleId, 'view'),
				isCurrentPage: false,
			}

			if (isOnEditPage) {
				return [
					rulesListBreadcrumbItem,
					earningRuleDetailBreadcrumb,
					{
						label: 'Edit rule',
						path: getEarningRulePath(earningRuleId, 'edit'),
						isCurrentPage: true,
					},
				]
			}

			return [rulesListBreadcrumbItem, { ...earningRuleDetailBreadcrumb, isCurrentPage: true }]
		}

		if (exclusionRuleId) {
			const exclusionRuleDetailBreadcrumb = {
				label: 'Exclusion',
				path: getExclusionRulePath(exclusionRuleId, 'view'),
				isCurrentPage: false,
			}
			if (isOnEditPage) {
				return [
					rulesListBreadcrumbItem,
					exclusionRuleDetailBreadcrumb,
					{
						label: 'Edit rule',
						path: getExclusionRulePath(exclusionRuleId, 'edit'),
						isCurrentPage: true,
					},
				]
			}

			return [rulesListBreadcrumbItem, { ...exclusionRuleDetailBreadcrumb, isCurrentPage: true }]
		}

		return []
	}, [amountSpent, earningStatus, earningRuleId, exclusionRuleId, exclusionStatus, isOnEditPage, points, t])

	const breadcrumbConfig = {
		homePath,
		items: breadcrumbItems,
	}

	const optionalButtons = useMemo(() => {
		const optionalButtonsArray: [OptionalButton?, OptionalButton?] = []

		if (hasGlobalManagerScope && !isOnEditPage) {
			optionalButtonsArray[0] = {
				label: t('rules:CREATE_EARNING_RULES:DELETE_RULE'),
				onClick: (): void => {
					handleDeleteRule()
				},
				icon: () => Trash2,
			}
			optionalButtonsArray[1] = {
				label: t('rules:CREATE_EARNING_RULES:EDIT'),
				onClick: handleEditRule,
				icon: () => Edit2,
			}
		}

		return optionalButtonsArray
	}, [handleDeleteRule, handleEditRule, hasGlobalManagerScope, isOnEditPage, t])

	return { optionalButtons, pageTitle, breadcrumbConfig }
}

export default useCreateRulesHeaderPayload
