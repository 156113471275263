import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph } from '@hexa-ui/components'
import SkuListTableV2 from '@/routes/rules/RulesRedesign/Components/SkuListTableV2/SkuListTableV2'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EARNING_RULES } from '@/utils/constants'
import * as Styled from './SkuList.styles'

export const SkuList = () => {
	const { t } = useTranslation()

	const requiredScopes = [CREATE_EARNING_RULES]
	const basePath = '/membership/rules/earning-rule'
	const { isEditMode } = useEditMode(basePath, requiredScopes)

	return (
		<Styled.ContainerCard border="medium" elevated="small" data-testid="create-rule-page-sku-list">
			<Heading id="sku-title" size="H3">
				{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SKUS')}
			</Heading>
			{isEditMode && (
				<Paragraph colortype="secondary" weight="medium">
					{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SKUS_DESCRIPTION')}
				</Paragraph>
			)}
			<SkuListTableV2 hasTableSearch hasToolbarButton isEditMode={isEditMode} />
		</Styled.ContainerCard>
	)
}
