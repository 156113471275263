import React from 'react'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { CREATE_EXCLUSION_LIST } from '@/utils/constants'
import { AudienceExclusionViewData } from './AudienceExclusionViewData/AudienceExclusionViewData'
import { AudienceExclusionEditData } from './AudienceExclusionEditData/AudienceExclusionEditData'

const AudienceExclusion = () => {
	const requiredScopes = [CREATE_EXCLUSION_LIST]
	const basePath = '/membership/rules/exclusion-rule'
	const { isEditMode } = useEditMode(basePath, requiredScopes)

	return isEditMode ? <AudienceExclusionEditData /> : <AudienceExclusionViewData />
}

export default AudienceExclusion
